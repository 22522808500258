header.appbar {
  //-webkit-box-shadow: 0 0 30px 10px rgba(0,0,0,.2);
  //box-shadow: 0 0 30px 10px rgba(0,0,0,.2);
  background: var(--color-header);
}

footer.appbar {
 background: var(--color-dark);
}

header.appbar, footer.appbar {
  height: 80px;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1200px) {
    display: none;
  }

  .logo {
    padding-left: 48px;
  }

  .menu {
    margin-left: 145px;
    margin-right: auto;

    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;

    a {
      margin-left: 32px;
      cursor: pointer;
      color: var(--color-text);
      font-weight: 600;

      &._active {
        text-decoration: underline;
        font-weight: 600;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  button.primary {
    width: 230px;
    margin-right: 48px;
    background-color: var(--color-primary);
    border-color: var(--color-primary);

    &:hover {
      background-color: var(--color-second-primary) !important;
      border-color: var(--color-second-primary) !important;
    }
  }
}


.lang-menu {
  .MuiMenu-paper {
    //width: 90px;
    height: fit-content;
    max-height: fit-content;
    padding: 0;
    transform: translate(-14px, -12px) !important;
    background: var(--color-background);
    box-shadow: 6px 12px 24px -3px rgba(31, 63, 116, 0.15);
    -webkit-box-shadow: 6px 12px 24px -3px rgba(31, 63, 116, 0.15);

    @media (max-width: 1200px) {
      transform: translate(44px, -42px) !important;
      border-color: var(--color-white);
      li {
        color: var(--color-text);
      }
    }

    li {
      padding: 12px 13px;
      font-size: 14px;

      &:hover {
        background: var(--color-background-light);
      }
    }

    img {
      margin-right: 8px;
    }
  }
}

.header-short {
  display: none;
  z-index: 10;
  position: fixed;
  top: 16px;
  left: 16px;
  @media (max-width: 1200px) {
    display: block;
  }

  button, button:hover, button:active, button:focus {
    background: var(--color-background);
  }
}

.header-mobile-menu {
  img.logo {
    width: auto;
    height: 56px;
  }

  .MuiDrawer-paper {
    padding: 0;
    border-radius: 30px;
    width: 100%;
    background: var(--color-header);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .close-btn {
      background: var(--color-primary);
      z-index: 100;
      position: absolute;
      top: 16px;
      right: 16px;
    }

    .menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 40%;
      margin: auto;

      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.02em;
      text-transform: uppercase;

      a {
        color: var(--color-text);

        &._active {
          font-weight: 800;
        }
      }
    }

    .lang-button {
      width: fit-content;
      margin: 0 0 24px 0;
    }
  }
}

button.lang-button, button.lang-button:hover, button.lang-button:focus, button.lang-button:active {
  background: transparent;
  width: 54px;
  min-width: 54px;
  padding: 0;
  margin: 0 44px 0 0;
  border: none;

  .title {
    text-transform: uppercase;
    margin-right: 12px;
    color: var(--color-white);
  }

  img {
    margin-right: 12px;
  }

}
