html {
    --color-primary: #069ea1;
    --color-second-primary: #11ccd1;
    --color-primary-hover: #3d5bdb;
    --color-disabled: #6c757d;
    --color-white: #FFFFFF;
    --color-text: #333;
    --color-text-gray: #6c757d;
    --color-placeholder: #989898;
    --color-header: #ffffff;
    --color-background-light: #ffffff;
    --color-background: #f5f5f5;
    --color-dark: #262c38;
    --color-black: #000000;
}
