.MuiTooltip-popper {
  div.MuiTooltip-tooltip {
    background: var(--color-background);
    color: var(--color-text);
    font-size: 16px;
    box-shadow: 0 3px 14px rgba(33, 67, 155, 0.1);
    max-width: 450px;
    padding: 16px 20px 16px 25px;
  }

  span.MuiTooltip-arrow::before {
    background: var(--color-background);
  }
}
